<template>
  <div class="fixed inset-0 flex items-center justify-center z-50">
    <div class="absolute inset-0 bg-slate-400/55 dark:bg-slate-900/65 backdrop-blur-sm"></div>
    <div class="relative bg-base-100 p-5 rounded-lg shadow-lg">
      <div class="flex items-center justify-center mb-3">
        <svg class="animate-spin h-6 w-6 mr-3 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
          </path>
        </svg>
        <span>Loading...</span>
      </div>
      <p class="text-center text-sm text-content-base">Please wait while the content is loading.</p>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style></style>