<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <BaseLoader v-if="appStore.getLoadingState" />
  <TransitionRoot
    appear
    class="overflow-hidden"
    :show="appStore.toasts.length > 0"
    enter="transition ease-in-out duration-300 transform"
    enter-from="-translate-x-full"
    enter-to="translate-x-4"
    leave="transition ease-in-out duration-300 transform"
    leave-from="translate-x-0"
    leave-to="-translate-x-full"
  >
    <div v-for="toast in appStore.toasts" class="toast toast-top toast-end">
      <div :class="`alert alert-${toast.status}`">
        <span>{{ toast.text }}</span>
      </div>
    </div>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { useMyAppStore } from "@/store/app";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useMyUserStore } from "~/store/user";
import { TransitionRoot } from "@headlessui/vue";
const appStore = useMyAppStore();
const userStore = useMyUserStore();
const fetchUser = async () => {
  await fetchUserAttributes()
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      console.log(error);
    });
};
onBeforeMount(async () => {
  await fetchUser();
});
</script>

<style>
.alert-success {
  background-color: #d1f5d3;
  color: #0f5132;
}

.alert-error {
  background-color: #f8d7da;
  color: #842029;
}

.alert-info {
  background-color: #d1ecf1;
  color: #0c5460;
}
</style>
