import { defineStore } from "pinia";
export type TToastStatus = "success" | "warning" | "error";
type ToastPayload = { timeout?: number; text: string };
const defaultTimeout = 5000;
const createToast = (text: string, status: TToastStatus): IToast => ({
  text,
  status,
  id: Math.random() * 1000,
});
interface IToast {
  text: string;
  status: TToastStatus;
  id: number;
}
export const useMyAppStore = defineStore({
  id: "appStore",
  getters: {
    getLoadingState: (state) => state.loadingCount > 0,
  },
  state: () => ({
    loadingCount: 0,
    toasts: [] as IToast[],
  }),
  actions: {
    incrementLoading() {
      this.loadingCount++;
    },
    decrementLoading() {
      this.loadingCount--;
    },
    updateToastState(payload: ToastPayload, status: TToastStatus) {
      const { text, timeout } = payload;
      const toast = createToast(text, status);
      this.toasts.push(toast);
      setTimeout(() => {
        this.toasts = this.toasts.filter((t) => t.id !== toast.id);
      }, timeout ?? defaultTimeout);
    },
    success(payload: ToastPayload) {
      this.updateToastState(payload, "success");
    },
    warning(payload: ToastPayload) {
      this.updateToastState(payload, "warning");
    },

    error(payload: ToastPayload) {
      this.updateToastState(payload, "error");
    },
  },
});
