import revive_payload_client_AZZEj3vkLf from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_f54T99mFq1 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_57q7yeqpo8 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_P24OrSbrlG from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_AzjNMEDLG5 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Ak3xH9manh from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_XFIMLyUVlv from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_gvdmWAVOf9 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@pinia+nuxt@0.5.5_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_z3SOXu9dxN from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import v_tooltip_FxHoU6W0Sl from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/maz-ui@3.47.1_chart.js@4.4.4_nuxt@3.13.2_vue-router@4.4.5_vue@3.5.12/node_modules/maz-ui/nuxt/runtime/plugins/v-tooltip.js";
import v_fullscreen_img_hhpYZuLmhg from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/maz-ui@3.47.1_chart.js@4.4.4_nuxt@3.13.2_vue-router@4.4.5_vue@3.5.12/node_modules/maz-ui/nuxt/runtime/plugins/v-fullscreen-img.js";
import v_click_outside_SFTGLu3P7Z from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/maz-ui@3.47.1_chart.js@4.4.4_nuxt@3.13.2_vue-router@4.4.5_vue@3.5.12/node_modules/maz-ui/nuxt/runtime/plugins/v-click-outside.js";
import v_lazy_img_CkrkWvfvQM from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/maz-ui@3.47.1_chart.js@4.4.4_nuxt@3.13.2_vue-router@4.4.5_vue@3.5.12/node_modules/maz-ui/nuxt/runtime/plugins/v-lazy-img.js";
import v_zoom_img_OinrP2vlRV from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/maz-ui@3.47.1_chart.js@4.4.4_nuxt@3.13.2_vue-router@4.4.5_vue@3.5.12/node_modules/maz-ui/nuxt/runtime/plugins/v-zoom-img.js";
import wait_7T7HhNw9gQ from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/maz-ui@3.47.1_chart.js@4.4.4_nuxt@3.13.2_vue-router@4.4.5_vue@3.5.12/node_modules/maz-ui/nuxt/runtime/plugins/wait.js";
import dialog_2mYTrflmlz from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/maz-ui@3.47.1_chart.js@4.4.4_nuxt@3.13.2_vue-router@4.4.5_vue@3.5.12/node_modules/maz-ui/nuxt/runtime/plugins/dialog.js";
import toaster_wf3XyB5Ivx from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/maz-ui@3.47.1_chart.js@4.4.4_nuxt@3.13.2_vue-router@4.4.5_vue@3.5.12/node_modules/maz-ui/nuxt/runtime/plugins/toaster.js";
import aos_m3Q02LAY9L from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/maz-ui@3.47.1_chart.js@4.4.4_nuxt@3.13.2_vue-router@4.4.5_vue@3.5.12/node_modules/maz-ui/nuxt/runtime/plugins/aos.js";
import plugin_MMotzCiDa0 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxt+icon@1.5.6_vite@5.4.8_vue@3.5.12/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_1rze2VsG7c from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import vue_datepicker_65xHnOJJH3 from "/opt/atlassian/pipelines/agent/build/plugins/vue-datepicker.js";
import amplify_Nj8AYDITi6 from "/opt/atlassian/pipelines/agent/build/plugins/amplify.js";
export default [
  revive_payload_client_AZZEj3vkLf,
  unhead_f54T99mFq1,
  router_57q7yeqpo8,
  payload_client_P24OrSbrlG,
  navigation_repaint_client_AzjNMEDLG5,
  check_outdated_build_client_Ak3xH9manh,
  chunk_reload_client_XFIMLyUVlv,
  plugin_vue3_gvdmWAVOf9,
  components_plugin_KR1HBZs4kY,
  prefetch_client_z3SOXu9dxN,
  v_tooltip_FxHoU6W0Sl,
  v_fullscreen_img_hhpYZuLmhg,
  v_click_outside_SFTGLu3P7Z,
  v_lazy_img_CkrkWvfvQM,
  v_zoom_img_OinrP2vlRV,
  wait_7T7HhNw9gQ,
  dialog_2mYTrflmlz,
  toaster_wf3XyB5Ivx,
  aos_m3Q02LAY9L,
  plugin_MMotzCiDa0,
  plugin_client_1rze2VsG7c,
  vue_datepicker_65xHnOJJH3,
  amplify_Nj8AYDITi6
]