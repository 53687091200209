import { signInWithRedirect, signOut,fetchUserAttributes } from 'aws-amplify/auth'
import { defineStore } from 'pinia'
import { type User } from '~/types/types'
export const useMyUserStore = defineStore({
  id: 'myUserStore',
  state: () => ({
    user: {} as User,
    isAuthenticated: false
   }),
   getters: {
    authenticated: (state) => state.isAuthenticated,
   },
  actions: {
    setUser(user: User) {
      this.user = user
    },
    async signInWithGoogle () {
      try {
        const res = await signInWithRedirect({ provider: 'Google' });
        return res;
      } catch (error) {
        throw error as Error;
      }
    },
    async signOut() {
      try {
        await signOut();
      } catch (error) {
        console.log(error);
      }
    },      
  }
})
