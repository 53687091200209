import { default as login103yOntRZlMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/login.vue?macro=true";
import { default as indexFhNg930NCAMeta } from "/opt/atlassian/pipelines/agent/build/pages/call-status/index.vue?macro=true";
import { default as index0XYtc9KWK2Meta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/[id]/index.vue?macro=true";
import { default as indexe5TXIUwNuBMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/index.vue?macro=true";
import { default as index8uDVIG3EixMeta } from "/opt/atlassian/pipelines/agent/build/pages/dashboard/index.vue?macro=true";
import { default as indexavgf1teUEEMeta } from "/opt/atlassian/pipelines/agent/build/pages/file-status/index.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as indexmOXYyonYhKMeta } from "/opt/atlassian/pipelines/agent/build/pages/jobs/index.vue?macro=true";
import { default as indexfP1Gau3Ts2Meta } from "/opt/atlassian/pipelines/agent/build/pages/jobs/new/index.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/login.vue")
  },
  {
    name: "call-status",
    path: "/call-status",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/call-status/index.vue")
  },
  {
    name: "candidates-id",
    path: "/candidates/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/[id]/index.vue")
  },
  {
    name: "candidates",
    path: "/candidates",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: index8uDVIG3EixMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dashboard/index.vue")
  },
  {
    name: "file-status",
    path: "/file-status",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/file-status/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "jobs",
    path: "/jobs",
    meta: indexmOXYyonYhKMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jobs/index.vue")
  },
  {
    name: "jobs-new",
    path: "/jobs/new",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jobs/new/index.vue")
  }
]