import { Amplify } from "aws-amplify";
import { demo, staging, dev, production } from "@/amplify";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const oauth = {
    domain: config.public.NUXT_APP_COGNITO_OAUTH_DOMAIN,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    // If you need to switch signout/signin urls dynamically --> call Amplify.config again
    redirectSignIn: config.public.NUXT_APP_REDIRECT_SIGN_IN_URL,
    redirectSignOut: config.public.NUXT_APP_REDIRECT_SIGN_OUT_URL,
    responseType: config.public.NUXT_APP_COGNITO_OAUTH_RESPONSE_TYPE, // or 'token', note that REFRESH token will only be generated when the responseType is code
  };
  console.log(config.public.NUXT_APP_ENV);
  if (config.public.NUXT_APP_ENV === "demo") {
    demo.oauth = oauth;
    Amplify.configure(demo);
  }
  if (config.public.NUXT_APP_ENV === "staging") {
    staging.oauth = oauth;
    Amplify.configure(staging);
  }
  if (
    config.public.NUXT_APP_ENV === "dev" ||
    config.public.NUXT_APP_ENV === "local"
  ) {
    dev.oauth = oauth;
    Amplify.configure(dev);
  }
  if (config.public.NUXT_APP_ENV === "production") {
    production.oauth = oauth;
    Amplify.configure(production);
  }
});
