export const staging = {
  aws_project_region: "us-east-2",
  aws_cognito_identity_pool_id:
    "us-east-2:236d4ff6-61c8-415b-9bee-f26f5ccced1e",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_55WiSRbyH",
  aws_user_pools_web_client_id: "7ajtpe3dfqhl9p7jgjp7pa9u51",
  oauth: {
    domain: "zy0w9ntrv762-staging.auth.us-east-2.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn:
      "http://localhost:3000,https://nonprod-pi.oneorigin.us,https://pi.oneorigin.us",
    redirectSignOut:
      "http://localhost:3000,https://nonprod-pi.oneorigin.us,https://pi.oneorigin.us",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["PICTURE", "EMAIL", "NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};
export const dev = {
  aws_project_region: "us-east-2",
  aws_cognito_identity_pool_id:
    "us-east-2:a027c8b7-684e-44b9-9a6e-3a357a4bcc20",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_Rxe4cBf5y",
  aws_user_pools_web_client_id: "766cnk31fquki8gdlnr9ttme8j",
  oauth: {
    domain: "zy0w9ntrv762-dev.auth.us-east-2.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn:
      "http://localhost:3000,https://nonprod-pi.oneorigin.us,https://pi.oneorigin.us",
    redirectSignOut:
      "http://localhost:3000,https://nonprod-pi.oneorigin.us,https://pi.oneorigin.us",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["PICTURE", "EMAIL", "NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};
export const local = {
  aws_project_region: "us-east-2",
  aws_cognito_identity_pool_id:
    "us-east-2:a027c8b7-684e-44b9-9a6e-3a357a4bcc20",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_Rxe4cBf5y",
  aws_user_pools_web_client_id: "766cnk31fquki8gdlnr9ttme8j",
  oauth: {
    domain: "zy0w9ntrv762-dev.auth.us-east-2.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn:
      "http://localhost:3000,https://nonprod-pi.oneorigin.us,https://pi.oneorigin.us",
    redirectSignOut:
      "http://localhost:3000,https://nonprod-pi.oneorigin.us,https://pi.oneorigin.us",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["PICTURE", "EMAIL", "NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};
export const demo = {
  aws_project_region: "us-east-2",
  aws_cognito_identity_pool_id:
    "us-east-2:968b1e66-a78a-42ce-bb07-8a7128ceda61",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_hJYVmkVw9",
  aws_user_pools_web_client_id: "1et5iraetnij2hu9919samlj3a",
  oauth: {
    domain: "zy0w9ntrv762-demo.auth.us-east-2.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn:
      "http://localhost:3000,https://nonprod-pi.oneorigin.us,https://pi.oneorigin.us",
    redirectSignOut:
      "http://localhost:3000,https://nonprod-pi.oneorigin.us,https://pi.oneorigin.us",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["PICTURE", "EMAIL", "NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};
export const production = {
  aws_project_region: "us-east-2",
  aws_cognito_identity_pool_id:
    "us-east-2:b3f208b2-8b0d-45d2-8968-b944cb001530",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_tAQ1fPPcI",
  aws_user_pools_web_client_id: "630rjrqbhiqbb3gats3ho3g8bi",
  oauth: {
    domain: "zy0w9ntrv762-production.auth.us-east-2.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn:
      "http://localhost:3000,https://nonprod-pi.oneorigin.us,https://pi.oneorigin.us",
    redirectSignOut:
      "http://localhost:3000,https://nonprod-pi.oneorigin.us,https://pi.oneorigin.us",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["PICTURE", "EMAIL", "NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};
